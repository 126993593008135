import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { Button, Table } from 'antd';

import { useGetProjectsQuery } from '../../api/projects';
import { useDeleteProject } from '../../hooks/projects/use-delete-project';
import {
  TProjectColumnsData,
  getProjectListTableColumns,
} from '../../tables/project';
import { CreateProjectModal } from '../modals/create-project-modal';

import { ColumnsType } from 'antd/lib/table';

export const HomePage: FC = () => {
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const { data, isLoading } = useGetProjectsQuery();
  const navigate = useNavigate();

  const [editProjectId, setEditProjectId] = useState<string | undefined>();

  const handleDeleteProject = useDeleteProject();

  const handleStartEdit = (id: string) => {
    setEditProjectId(id);
    setCreateProjectModalOpen(true);
  };

  const handleAddProject = () => {
    setEditProjectId(undefined);
    setCreateProjectModalOpen(true);
  };

  const handleOpenProject = (id: string) => {
    navigate(id);
  };

  const columnData: TProjectColumnsData[] = useMemo(
    () =>
      data
        ? data.projects.map((el) => ({
            key: el.id,
            name: el.name,
            feedUrl: el.feedURL,
            scheduleEnabled: el.scheduleEnabled,
            createAt: el.createAt,
            updateAt: el.updateAt,
          }))
        : [],
    [data]
  );

  const columns: ColumnsType<TProjectColumnsData> = useMemo(
    () =>
      getProjectListTableColumns([
        {
          content: 'Открыть',
          key: 'open',
          icon: <FolderOutlined />,
          action: handleOpenProject,
        },
        {
          content: 'Редактировать',
          key: 'edit',
          icon: <EditOutlined />,
          action: handleStartEdit,
        },
        {
          content: 'Удалить',
          key: 'delete',
          icon: <DeleteOutlined />,
          action: handleDeleteProject,
        },
      ]),
    [handleDeleteProject]
  );

  return (
    <div>
      <Button
        type="primary"
        onClick={handleAddProject}
        style={{
          marginBottom: 20,
        }}
      >
        Добавить проект
      </Button>

      <Table
        dataSource={columnData}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <CreateProjectModal
        open={createProjectModalOpen}
        setOpen={setCreateProjectModalOpen}
        existProjectId={editProjectId}
      />
    </div>
  );
};
