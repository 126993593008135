export enum EGenerationTaskStatus {
  QUEUED = 'QUEUED',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

type EGenerationTaskStatusWithEmpty = EGenerationTaskStatus | 'empty';

export const TEXT_BY_STATUS: Record<
  EGenerationTaskStatusWithEmpty,
  {
    title: string;
    description: string;
  }
> = {
  [EGenerationTaskStatus.DONE]: {
    title: 'Генерация выполнена',
    description: 'Генерация успешно выполнена, данные обновлены',
  },
  [EGenerationTaskStatus.QUEUED]: {
    title: 'Генерация в очереди',
    description: 'Идет процесс генерации баннеров, пожалуйста подождите',
  },
  [EGenerationTaskStatus.ERROR]: {
    title: 'Произошла ошибка при генерации',
    description: 'Произошла непредвиденная ошибка при генерации баннера',
  },
  empty: {
    title: 'Нет информации',
    description: 'Информация о текущих задачах генерации не загружена',
  },
};

export type TGenerationTask = {
  bannerId: null | string;
  createAt: string;
  id: string;
  project: { id: string };
  projectId: string;
  updateAt: string;
  status: EGenerationTaskStatus;
};

export type TStartGenerationResponse = TGenerationTask[];

export type TCurrentFullStatus = {
  lastStatus: EGenerationTaskStatusWithEmpty;
  lastTaskUpdateDate: string;
  countByStatus: Record<EGenerationTaskStatus, number>;
  isLoading: boolean;
};
