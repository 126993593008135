import { baseEmptyApi } from '../api';

export const commonApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    deleteFile: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/files/${id}`,
        method: 'DELETE',
      }),
    }),
    downloadFilesZip: builder.mutation<void, string[]>({
      query: (urls) => ({
        url: 'api/files/download-zip',
        method: 'POST',
        body: { urls },
        cache: 'no-cache',
        responseHandler: async (response) => {
          window.location.assign(
            window.URL.createObjectURL(await response.blob())
          );
        },
      }),
    }),
  }),
});

export const { useDeleteFileMutation, useDownloadFilesZipMutation } = commonApi;
