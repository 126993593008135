import { FC, Fragment, memo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Alert, ConfigProvider, Space, Tag, Typography } from 'antd';
import dayjs from 'dayjs';

import {
  EGenerationTaskStatus,
  TCurrentFullStatus,
  TEXT_BY_STATUS,
} from '../../../types/task';

import { AlertProps } from 'antd/es/alert/Alert';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type TProps = {
  projectName?: string;
  projectTaskFullStatus: TCurrentFullStatus;
};

const AlertTypeByStatus: Record<EGenerationTaskStatus, AlertProps['type']> = {
  [EGenerationTaskStatus.QUEUED]: 'info',
  [EGenerationTaskStatus.DONE]: 'success',
  [EGenerationTaskStatus.ERROR]: 'error',
};

const AlertWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: ${({ theme }) => theme.MAIN.BLACK.C100};
  background-color: ${({ theme }) => theme.MAIN.WHITE.C100_TRANSPARENT};
`;

const ProjectPageHeaderSection: FC<TProps> = ({
  projectName,
  projectTaskFullStatus,
}) => {
  const isEmptyDate = projectTaskFullStatus.lastStatus === 'empty';

  return (
    <Wrapper>
      <Typography.Title>{projectName}</Typography.Title>
      <AlertWrapper>
        <ConfigProvider
          theme={{
            components: {
              Alert: {
                withDescriptionPadding: '10px 12px',
              },
            },
          }}
        >
          <Alert
            message={TEXT_BY_STATUS[projectTaskFullStatus.lastStatus].title}
            closable={
              projectTaskFullStatus.lastStatus === EGenerationTaskStatus.DONE
            }
            description={
              <>
                <Typography.Paragraph>
                  {TEXT_BY_STATUS[projectTaskFullStatus.lastStatus].description}
                </Typography.Paragraph>
                <Space>
                  <Tag color="blue" title="Дата актуальности">
                    {dayjs(projectTaskFullStatus.lastTaskUpdateDate).format(
                      'DD.MM.YYYY HH:mm'
                    )}
                  </Tag>
                  <Tag
                    color="success"
                    title={TEXT_BY_STATUS[EGenerationTaskStatus.DONE].title}
                  >
                    {projectTaskFullStatus.countByStatus.DONE}
                  </Tag>
                  <Tag
                    color="blue"
                    title={TEXT_BY_STATUS[EGenerationTaskStatus.QUEUED].title}
                  >
                    {projectTaskFullStatus.countByStatus.QUEUED}
                  </Tag>
                  <Tag
                    color="error"
                    title={TEXT_BY_STATUS[EGenerationTaskStatus.ERROR].title}
                  >
                    {projectTaskFullStatus.countByStatus.ERROR}
                  </Tag>
                </Space>
              </>
            }
            type={
              AlertTypeByStatus[
                isEmptyDate
                  ? EGenerationTaskStatus.QUEUED
                  : (projectTaskFullStatus.lastStatus as EGenerationTaskStatus)
              ]
            }
            showIcon
            icon={
              projectTaskFullStatus.isLoading ||
              projectTaskFullStatus.lastStatus ===
                EGenerationTaskStatus.QUEUED ? (
                <LoadingOutlined />
              ) : undefined
            }
          />
        </ConfigProvider>
        {projectTaskFullStatus.isLoading && (
          <LoadingWrapper>
            <LoadingOutlined />
          </LoadingWrapper>
        )}
      </AlertWrapper>
    </Wrapper>
  );
};

export default memo(ProjectPageHeaderSection);
