import { Dayjs } from 'dayjs';

export enum EYandexCurrencyCode {
  RUR = 'RUR',
  RUB = 'RUB',
  UAH = 'UAH',
  BYN = 'BYN',
  KZT = 'KZT',
  USD = 'USD',
  EUR = 'EUR',
}

type TFeedCurrency = {
  id: EYandexCurrencyCode;
  rate: number;
};

export type TCategory = {
  id: number;
  name: string;
  parentId?: number;
};

type TDeliveryOption<OrderDateType = Dayjs> = {
  cost: number;
  days: number;
  ['order-before']?: OrderDateType;
};

export type TYandexFeed<OrderDateType = Dayjs> = {
  name: string;
  company: string;
  url: string;
  platform?: string;
  version?: string;
  agency?: string;
  email?: string;
  currencies: TFeedCurrency[];
  categories: TCategory[];
  delivery: boolean;
  ['delivery-options']?: TDeliveryOption<OrderDateType>[];
  pickup: boolean;
  ['pickup-options']?: TDeliveryOption<OrderDateType>[];
  enable_auto_discounts: boolean;
};
