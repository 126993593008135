import type { Middleware, PayloadAction } from '@reduxjs/toolkit';
import { MiddlewareAPI } from 'redux';

import { authApi, baseEmptyApi } from '../../api';
import { logout } from '../features/user/auth.slice';
import { AppDispatch, RootState } from '../index';

function isPayloadApiAction(
  action: unknown | PayloadAction
): action is PayloadAction {
  return (
    (action as PayloadAction).type !== undefined &&
    (action as PayloadAction).type.startsWith(baseEmptyApi.reducerPath)
  );
}

const authMiddleware: Middleware =
  (api: MiddlewareAPI<AppDispatch, RootState>) => (next) => (action) => {
    if (isPayloadApiAction(action)) {
      if (authApi.endpoints.refreshToken.matchRejected(action)) {
        api.dispatch(logout());
      }
      if (
        authApi.endpoints.checkToken.matchRejected(action) &&
        action.error.name !== 'ConditionError'
      ) {
        api.dispatch(authApi.endpoints.refreshToken.initiate());
      }
    }

    return next(action);
  };

export default authMiddleware;
