import { FC } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Empty, Image } from 'antd';

import { useGetProjectBannersQuery } from '../../api/projects';
import { TTextBool, booleanToText, textToBoolean } from '../../utils/ui';

type TProps = {
  projectId?: string;
};

const Wrapper = styled.div<{
  loading: TTextBool;
  isEmpty: TTextBool;
}>`
  display: ${({ loading, isEmpty }) =>
    textToBoolean(loading) || textToBoolean(isEmpty) ? 'flex' : 'grid'};
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 18px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  min-height: 200px;
  font-size: 34px;
`;

const ImageCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
  }
`;

const ProjectBannersTab: FC<TProps> = ({ projectId }) => {
  const { data, isLoading } = useGetProjectBannersQuery(projectId as string, {
    skip: !projectId,
  });

  return (
    <Wrapper
      loading={booleanToText(isLoading)}
      isEmpty={booleanToText(data?.banners?.length === 0)}
    >
      {isLoading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      {!isLoading &&
        data?.banners?.map((banner) => (
          <ImageCard key={banner.id}>
            <Image src={banner.imageURL} />
          </ImageCard>
        ))}
      {data?.banners.length === 0 && !isLoading && (
        <Empty
          description="Баннеры отсутствуют"
          image={Empty.PRESENTED_IMAGE_DEFAULT}
        />
      )}
    </Wrapper>
  );
};

export default ProjectBannersTab;
