import { FC } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

type TProps = {
  items: MenuProps['items'];
};

const TableActionComponent: FC<TProps> = ({ items }) => (
  <Dropdown menu={{ items }}>
    <Button
      aria-label="Показать активные действия"
      aria-haspopup="dialog"
      shape="circle"
    >
      <EllipsisOutlined />
    </Button>
  </Dropdown>
);

export default TableActionComponent;
