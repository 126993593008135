import { FC, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Form, Input } from 'antd';

import { useRegisterMutation } from '../../api/auth';
import { RegistrationRequest } from '../../api/auth/types';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { EPaths } from '../../routes/route-paths';
import { EMAIL_REGEX } from '../../utils/regex';

const RegisterPage: FC = () => {
  const { messageApi } = useAppNotificationApi();
  const [register, { isLoading }] = useRegisterMutation();

  const handleRegister = useCallback(async (values: RegistrationRequest) => {
    try {
      const result = await register(values);

      if ('error' in result) {
        messageApi.error('Не удалось зарегистрироваться`');
      }
    } catch (e) {
      messageApi.error('Не удалось зарегистрироваться');
    }
  }, []);

  return (
    <Form<RegistrationRequest>
      name="register"
      onFinish={handleRegister}
      layout="vertical"
      requiredMark={false}
      style={{
        width: '100%',
      }}
    >
      <Form.Item
        name="publicKey"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          {
            pattern: EMAIL_REGEX,
            message: 'Email не валиден',
          },
        ]}
      >
        <Input type="email" autoComplete="email" placeholder="email@mail.ru" />
      </Form.Item>
      <Form.Item
        name="privateKey"
        label="Пароль"
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          {
            min: 8,
            max: 32,
            message: 'Пароль должен содержать от 8 до 32 символов',
          },
        ]}
      >
        <Input.Password type="password" autoComplete="new-password" />
      </Form.Item>
      <Form.Item
        name="rePrivateKey"
        label="Повтор пароля"
        dependencies={['privateKey']}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('privateKey') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Пароли не совпадают'));
            },
          }),
        ]}
      >
        <Input.Password type="password" autoComplete="new-password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Зарегистрироваться
        </Button>
        <NavLink to={EPaths.LOGIN}>
          <Button type="link">У меня есть аккаунт</Button>
        </NavLink>
      </Form.Item>
    </Form>
  );
};

export default RegisterPage;
