import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useCheckTokenQuery, useUserInfoQuery } from '../../api/auth';
import { ERouteParams } from '../../routes/route-params';
import { EPaths, UNSECURE_PATHS } from '../../routes/route-paths';
import { useAppSelector } from '../../state';
import { selectAuth } from '../../state/features/user/auth.slice';

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { authed, authorizedError } = useAppSelector(selectAuth);
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useCheckTokenQuery(undefined, {
    skip: !authed || authorizedError,
  });

  useUserInfoQuery(undefined, {
    skip: !authed || authorizedError,
    refetchOnMountOrArgChange: true, // Нужно для повторного запроса после logout
  });

  useEffect(() => {
    const isUnsecurePath = UNSECURE_PATHS.some((el) =>
      el === EPaths.HOME
        ? location.pathname === el
        : location.pathname.startsWith(el)
    );

    if (authed && isUnsecurePath) {
      const redirectUrl = searchParams.get(ERouteParams.REDIRECT_URL);
      searchParams.delete(ERouteParams.REDIRECT_URL);

      navigate(`${redirectUrl || EPaths.HOME}?${searchParams.toString()}`);
    } else if (!authed && !isUnsecurePath) {
      if (location.pathname !== EPaths.HOME) {
        searchParams.set(ERouteParams.REDIRECT_URL, location.pathname);
      }

      navigate(`${EPaths.LOGIN}?${searchParams.toString()}`);
    }
  }, [authed, location, searchParams]);

  return children;
};

export default AuthProvider;
