import { FC, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { Button, Form, Input } from 'antd';

import { useLoginMutation } from '../../api/auth';
import { LoginRequest } from '../../api/auth/types';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { EPaths } from '../../routes/route-paths';
import { EMAIL_REGEX } from '../../utils/regex';

const LoginPage: FC = () => {
  const { messageApi } = useAppNotificationApi();
  const [login, { isLoading }] = useLoginMutation();

  const handleLogin = useCallback(async (values: LoginRequest) => {
    try {
      const result = await login(values);

      if ('error' in result) {
        messageApi.error('Не удалось войти`');
      }
    } catch (e) {
      messageApi.error('Не удалось войти');
    }
  }, []);

  return (
    <Form<LoginRequest>
      name="login"
      onFinish={handleLogin}
      layout="vertical"
      requiredMark={false}
    >
      <Form.Item
        name="publicKey"
        label="Email"
        rules={[
          { required: true, message: 'Введите Email' },
          {
            pattern: EMAIL_REGEX,
            message: 'Email не валиден',
          },
        ]}
      >
        <Input type="email" autoComplete="email" placeholder="email@mail.ru" />
      </Form.Item>
      <Form.Item
        name="privateKey"
        label="Пароль"
        rules={[{ required: true, message: 'Введите пароль' }]}
      >
        <Input.Password autoComplete="current-password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Войти
        </Button>
        <NavLink to={EPaths.REGISTER}>
          <Button type="link">У меня нет аккаунта</Button>
        </NavLink>
      </Form.Item>
    </Form>
  );
};

export default LoginPage;
