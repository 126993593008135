import { Global, Theme, css, withTheme } from '@emotion/react';
import { FC } from 'react';

import { APP_ROOT_ID } from '../constants';

export enum ECSSVars {
  APP_MENU_WIDTH = '--menu-width',
}

type TProps = {
  theme: Theme;
};

const GlobalStyles: FC<TProps> = ({ theme }) => (
  <Global
    styles={css`
      :root {
        ${ECSSVars.APP_MENU_WIDTH}: 320px;
      }

      html {
        background-color: ${theme.MAIN.WHITE.C100};
        color: ${theme.MAIN.TEXT.C100};
      }

      *,
      *::after,
      *::before {
        box-sizing: border-box;
      }

      html,
      body {
        padding: 0;
        margin: 0;
      }

      #${APP_ROOT_ID} {
        height: 100vh;
        width: 100vw;

        &::-webkit-scrollbar {
          background: transparent;
          width: 10px;
          max-width: 10px;
          height: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: ${theme.MAIN.BLACK.C300};
          border-radius: 5px;
          width: 10px;
          height: 10px;
        }
      }
    `}
  />
);

export default withTheme(GlobalStyles);
