import { FC, useMemo } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Descriptions, Empty, Image } from 'antd';
import dayjs from 'dayjs';

import { useGetProjectTemplateQuery } from '../../api/projects';
import { OfferTextDescription } from '../../components/ui/offer-description';

import { DescriptionsProps } from 'antd/es/descriptions';

type TProps = {
  projectId?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  min-height: 200px;
  font-size: 34px;
`;

const DescriptionsWrapper = styled.div`
  width: 100%;
`;

const ProjectTemplateTab: FC<TProps> = ({ projectId }) => {
  const { data, isLoading, error } = useGetProjectTemplateQuery(
    projectId as string,
    {
      skip: !projectId,
    }
  );

  const descriptionItems: DescriptionsProps['items'] = useMemo(
    () =>
      data
        ? [
            {
              key: 'createAt',
              label: 'Дата создания',
              children: (
                <OfferTextDescription>
                  {dayjs(data.createAt).format('DD MMMM YYYY')}
                </OfferTextDescription>
              ),
              span: 3,
            },
            {
              key: 'width',
              label: 'Ширина',
              children: (
                <OfferTextDescription>{data.width} px</OfferTextDescription>
              ),
              span: 3,
            },
            {
              key: 'height',
              label: 'Высота',
              children: (
                <OfferTextDescription>{data.height} px</OfferTextDescription>
              ),
              span: 3,
            },
            {
              key: 'updateAt',
              label: 'Дата обновления',
              children: (
                <OfferTextDescription>
                  {dayjs(data.updateAt).format('DD MMMM YYYY')}
                </OfferTextDescription>
              ),
              span: 3,
            },
            {
              key: 'baseImage',
              label: 'Базовое изображение',
              children: (
                <Image
                  src={data.frame.baseFileLocation}
                  style={{
                    maxWidth: 320,
                    maxHeight: 320,
                    width: '100%',
                    height: '100%',
                  }}
                />
              ),
              span: 3,
            },
            {
              key: 'originalImage',
              label: 'Оригинальный баннер',
              children: (
                <Image
                  src={data.frame.asisFileLocation}
                  style={{
                    maxWidth: 320,
                    maxHeight: 320,
                    width: '100%',
                    height: '100%',
                  }}
                />
              ),
              span: 3,
            },
          ]
        : [],
    [data]
  );

  const isTemplateEmpty = useMemo(
    () =>
      error &&
      'status' in error &&
      typeof error.status === 'number' &&
      error.status === 404,
    [error]
  );

  return (
    <Wrapper>
      {isLoading && (
        <LoadingWrapper>
          <LoadingOutlined />
        </LoadingWrapper>
      )}
      {isTemplateEmpty && (
        <LoadingWrapper>
          <Empty description="Шабон отсутствует" />
        </LoadingWrapper>
      )}
      {data && (
        <DescriptionsWrapper>
          <Descriptions
            bordered
            title="Текущий шаблон"
            items={descriptionItems}
          />
        </DescriptionsWrapper>
      )}
    </Wrapper>
  );
};

export default ProjectTemplateTab;
