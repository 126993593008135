import { FC } from 'react';

import { Checkbox, Form } from 'antd';

import DeliveryOptionsListForm from './delivery-options-list-form';
import { FormSubTitle } from './form-components';

const DeliverAndPickupForm: FC = () => (
  <>
    <Form.Item
      label="Доставка"
      tooltip="Возможность курьерской доставки (по всем регионам, в которые доставляет магазин)."
      name="delivery"
      valuePropName="checked"
    >
      <Checkbox>Курьерская доставка</Checkbox>
    </Form.Item>
    <Form.Item noStyle dependencies={['delivery']}>
      {({ getFieldValue }) => {
        const isDeliveryActive = !!getFieldValue('delivery');

        if (!isDeliveryActive) {
          return null;
        }

        return (
          <>
            <FormSubTitle>Стоимость и сроки курьерской доставки</FormSubTitle>
            <DeliveryOptionsListForm name="delivery-options" />
          </>
        );
      }}
    </Form.Item>
    <Form.Item
      label="Самовывоз"
      tooltip="Возможность самовывоза из пунктов выдачи (во всех регионах, в которые доставляет магазин)."
      name="pickup"
      valuePropName="checked"
    >
      <Checkbox>Возможность самовывоза</Checkbox>
    </Form.Item>
    <Form.Item noStyle dependencies={['pickup']}>
      {({ getFieldValue }) => {
        const isDeliveryActive = !!getFieldValue('pickup');

        if (!isDeliveryActive) {
          return null;
        }

        return (
          <>
            <FormSubTitle>Стоимость и сроки самовывоза</FormSubTitle>
            <DeliveryOptionsListForm name="pickup-options" />
          </>
        );
      }}
    </Form.Item>
  </>
);

export default DeliverAndPickupForm;
