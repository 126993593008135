import { FC, PropsWithChildren, ReactNode, memo } from 'react';

import styled from '@emotion/styled';
import { Breadcrumb } from 'antd';

import { BreadcrumbProps } from 'antd/es/breadcrumb/Breadcrumb';

const Wrapper = styled.div`
  position: sticky;
  top: 20px;
  left: 0;
  background-color: ${({ theme }) => theme.MAIN.WHITE.C200};
  z-index: 10;
  padding: 12px;
`;

const ContentWrapper = styled.div`
  margin-top: 20px;
  height: auto;
`;

const ContentHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const ContentExtraWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

type TProps = {
  breadcrumbList: BreadcrumbProps['items'];
  extraButtons?: ReactNode | ReactNode[];
};

const AppSubjectPageLayout: FC<PropsWithChildren<TProps>> = memo(
  ({ breadcrumbList, children, extraButtons }) => (
    <div>
      <Wrapper>
        <ContentHeader>
          <Breadcrumb items={breadcrumbList} />
          {extraButtons && (
            <ContentExtraWrapper>{extraButtons}</ContentExtraWrapper>
          )}
        </ContentHeader>
      </Wrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </div>
  )
);

export default AppSubjectPageLayout;
