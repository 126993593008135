import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Form, Input } from 'antd';

import { THEME_LIGHT } from '../../styles/theme';
import { URL_REGEX } from '../../utils/regex';
import { NBSP } from '../../utils/typography';

const BaseYandexFeedForm: FC = () => (
  <>
    <Form.Item
      label="Название магазина"
      tooltip={`
            Короткое название магазина. В названии нельзя использовать слова, которые не относятся к наименованию магазина (например «лучший», «дешевый»), указывать номер телефона и т. п.
            
            Название магазина должно совпадать с фактическим названием, которое публикуется на сайте. Если требование не соблюдается, Яндекс.Маркет может самостоятельно изменить название без уведомления магазина.          
          `}
      name="name"
      rules={[
        {
          required: true,
          message: 'Поле обязательно для заполнения',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Наименование компании"
      tooltip="Полное наименование компании, владеющей магазином. Не публикуется."
      name="company"
      rules={[
        {
          required: true,
          message: 'Поле обязательно для заполнения',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="URL главной страницы магазина"
      tooltip={
        <>
          Максимальная длина ссылки — 2048 символов. Допускаются кириллические
          ссылки. URL‑адрес формируется на основе стандарта
          {NBSP}
          <Link
            to="https://tools.ietf.org/html/rfc3986"
            target="_blank"
            style={{
              color: THEME_LIGHT.ACCENT.C300,
            }}
          >
            RFC 3986
          </Link>
          . Если вы используете кириллический сайт, он должен быть доступен по
          протоколу HTTP (не HTTPS). Рекомендуем преобразовать ссылку с помощью
          Punycode.
        </>
      }
      name="url"
      rules={[
        {
          required: true,
          message: 'Поле обязательно для заполнения',
        },
        {
          pattern: URL_REGEX,
          message: 'URL не валиден',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="CMS"
      tooltip="Система управления контентом, на основе которой работает магазин (CMS)."
      name="platform"
    >
      <Input />
    </Form.Item>
    <Form.Item dependencies={['platform']} noStyle>
      {({ getFieldValue }) => {
        const isCMSSpecified = !!getFieldValue('platform');

        if (isCMSSpecified) {
          return (
            <Form.Item label="Версия CMS" name="version">
              <Input />
            </Form.Item>
          );
        }

        return null;
      }}
    </Form.Item>
    <Form.Item
      label="Наименование агентства"
      tooltip="Наименование агентства, которое оказывает техническую поддержку магазину и отвечает за работоспособность сайта."
      name="agency"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Контактный адрес техподдержки"
      tooltip="Контактный адрес разработчиков CMS или агентства, осуществляющего техподдержку."
      name="email"
    >
      <Input />
    </Form.Item>
  </>
);

export default BaseYandexFeedForm;
