import { BasePaginationProps } from '../../types/api';
import { TStartGenerationResponse } from '../../types/task';
import { baseEmptyApi } from '../api';
import {
  TCreateProjectForm,
  TFullProject,
  TPatchProjectPayload,
  TProject,
  TProjectBannersResponse,
  TProjectTasksResponse,
  TProjectTemplateResponse,
  TProjectsResponse,
} from './types';

export const projectsApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProjects: builder.query<TProjectsResponse, BasePaginationProps | void>({
      query: (
        credentials = {
          take: 10,
          skip: 0,
        }
      ) => ({
        url: 'api/projects',
        method: 'GET',
        params: {
          ...credentials,
        },
      }),
    }),
    getById: builder.query<TFullProject, string>({
      query: (id) => ({
        url: `api/projects/${id}`,
        method: 'GET',
      }),
    }),
    patchProject: builder.mutation<TProject, TPatchProjectPayload>({
      query: (payload) => ({
        url: `api/projects/${payload.id}`,
        method: 'PATCH',
        body: payload.data,
      }),
      async onQueryStarted(patchData, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectsApi.util.updateQueryData(
            'getProjects',
            undefined,
            (draft) => {
              draft.projects = draft.projects.map((el) =>
                el.id === patchData.id
                  ? {
                      ...el,
                      ...patchData.data,
                    }
                  : el
              );
            }
          )
        );

        const updateByIdResult = dispatch(
          projectsApi.util.updateQueryData('getById', patchData.id, (data) => {
            if (data.id === patchData.id) {
              return {
                ...data,
                ...patchData.data,
              };
            }
            return data;
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          updateByIdResult.undo();
        }
      },
    }),
    createProject: builder.mutation<TProject, TCreateProjectForm>({
      query: (body) => ({
        url: 'api/projects',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          dispatch(
            projectsApi.util.updateQueryData(
              'getProjects',
              undefined,
              (draft) => {
                draft.projects.push(response.data);
              }
            )
          );
        } catch {
          // Обработка ошибки
        }
      },
    }),
    deleteProject: builder.mutation<void, string>({
      query: (id) => ({
        url: `api/projects/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          projectsApi.util.updateQueryData(
            'getProjects',
            undefined,
            (draft) => {
              draft.projects = draft.projects.filter((el) => el.id !== id);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
    }),
    getProjectBanners: builder.query<TProjectBannersResponse, string>({
      query: (projectId) => ({
        url: `api/projects/${projectId}/banners`,
        method: 'GET',
      }),
    }),
    getProjectGenerationTasks: builder.query<TProjectTasksResponse, string>({
      query: (projectId) => ({
        url: `/api/projects/${projectId}/generation-tasks`,
        method: 'GET',
      }),
    }),
    startProjectBannersGeneration: builder.mutation<
      TStartGenerationResponse,
      string
    >({
      query: (projectId) => ({
        url: `/api/generation-tasks/schedule/${projectId}`,
        method: 'POST',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            projectsApi.endpoints.getProjectGenerationTasks.initiate(id)
          );
        } catch (e) {
          /* empty */
        }
      },
    }),
    getProjectTemplate: builder.query<TProjectTemplateResponse, string>({
      query: (projectId) => ({
        url: `/api/projects/${projectId}/template`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  usePatchProjectMutation,
  useGetByIdQuery,
  useGetProjectBannersQuery,
  useStartProjectBannersGenerationMutation,
  useGetProjectGenerationTasksQuery,
  useGetProjectTemplateQuery,
} = projectsApi;
