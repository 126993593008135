import {
  AppstoreAddOutlined,
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { TMenuItem } from '../components/layouts/app-base-layout';
import { EPaths } from '../routes/route-paths';

export const APP_BASE_MENU: Omit<TMenuItem, 'active'>[] = [
  {
    key: 'home',
    label: 'Проекты',
    to: EPaths.HOME,
    Icon: HomeOutlined,
  },
  {
    key: 'feeds',
    label: 'Фиды',
    to: EPaths.FEEDS,
    Icon: AppstoreAddOutlined,
  },
  {
    key: 'profile',
    label: 'Профиль',
    to: EPaths.PROFILE,
    Icon: UserOutlined,
  },
];
