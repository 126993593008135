import { useCallback } from 'react';

import { useDeleteProjectMutation } from '../../api/projects';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';

export function useDeleteProject(callBack?: () => void) {
  const [deleteProject] = useDeleteProjectMutation();
  const { messageApi } = useAppNotificationApi();

  return useCallback(
    async (id?: string) => {
      if (id) {
        try {
          await deleteProject(id).unwrap();
          messageApi.success('Проект успешно удален');
          callBack?.();
        } catch (e) {
          messageApi.error('Возникла ошибка при удалении проекта');
        }
      }
    },
    [callBack]
  );
}
