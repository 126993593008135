import {
  FC,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  Link,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileZipOutlined,
  HomeOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Space, Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';

import { useDownloadFilesZipMutation } from '../../api/common';
import {
  useGetByIdQuery,
  useGetProjectBannersQuery,
  useGetProjectGenerationTasksQuery,
  useStartProjectBannersGenerationMutation,
} from '../../api/projects';
import AppSubjectPageLayout from '../../components/layouts/app-base-layout/app-subject-page-layout';
import ProjectPageHeaderSection from '../../components/ui/project-page-header-section';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { useDeleteProject } from '../../hooks/projects/use-delete-project';
import { EPathKeyParams, EPaths, ESubPaths } from '../../routes/route-paths';
import { EGenerationTaskStatus, TCurrentFullStatus } from '../../types/task';
import { CreateProjectModal } from '../modals/create-project-modal';
import ProjectBannersTab from '../project/project-banners-tab';
import ProjectMainInfoTab from '../project/project-main-info-tab';
import ProjectTemplateTab from '../project/project-template-tab';

import { BreadcrumbProps } from 'antd/es/breadcrumb/Breadcrumb';

enum EProjectTab {
  PROJECT = 'project',
  BANNERS = 'banners',
  TEMPLATE = 'template',
}

const projectTabs: TabsProps['items'] = [
  {
    key: EProjectTab.PROJECT,
    label: 'Основная информация',
  },
  {
    key: EProjectTab.BANNERS,
    label: 'Баннеры',
  },
  {
    key: EProjectTab.TEMPLATE,
    label: 'Шаблон',
  },
];

const ProjectPage: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const projectId = useMemo(() => params[EPathKeyParams.PROJECT_ID], [params]);

  const [lastTaskIsQueued, setLastTaskIsQueued] = useState(false);

  const [downloadBannersPending, setDownloadBannersPending] = useState(false);

  const [downloadFiles] = useDownloadFilesZipMutation();

  const { data: projectTaskData, isLoading: tasksIsLoading } =
    useGetProjectGenerationTasksQuery(projectId as string, {
      skip: !projectId,
      pollingInterval: lastTaskIsQueued ? 5000 : undefined,
    });

  const projectTaskFullStatus: TCurrentFullStatus = useMemo(() => {
    let result: TCurrentFullStatus = {
      lastStatus: 'empty',
      lastTaskUpdateDate: dayjs().toISOString(),
      isLoading: tasksIsLoading,
      countByStatus: {
        [EGenerationTaskStatus.DONE]: 0,
        [EGenerationTaskStatus.QUEUED]: 0,
        [EGenerationTaskStatus.ERROR]: 0,
      },
    };

    if (
      projectTaskData?.generationTasks &&
      Array.isArray(projectTaskData.generationTasks) &&
      projectTaskData.generationTasks.length > 0
    ) {
      [...projectTaskData.generationTasks]
        ?.sort((a, b) =>
          b.createAt && a.createAt
            ? dayjs(a.createAt).diff(dayjs(b.createAt), 'hours')
            : 0
        )
        ?.forEach((task, index) => {
          if (index === (projectTaskData?.generationTasks?.length ?? 0) - 1) {
            result = {
              ...result,
              lastStatus: task.status,
              lastTaskUpdateDate: task.updateAt,
            };
          }

          result = {
            ...result,
            countByStatus: {
              ...result.countByStatus,
              [task.status]: result.countByStatus[task.status] + 1,
            },
          };
        });
    }

    setLastTaskIsQueued(
      !!projectTaskData?.generationTasks?.some(
        (el) => el.status === EGenerationTaskStatus.QUEUED
      )
    );

    return result;
  }, [projectTaskData, tasksIsLoading]);

  const [startGeneration, { isLoading: isStartGenerationLoading }] =
    useStartProjectBannersGenerationMutation();

  const { messageApi } = useAppNotificationApi();

  const [bannerActiveTab, setBannerActivePage] = useState<EProjectTab>(
    EProjectTab.PROJECT
  );

  const onBannerTabKeyChange = useCallback((key: string) => {
    let path: string;

    switch (key as EProjectTab) {
      case EProjectTab.PROJECT:
        path = '';
        break;
      case EProjectTab.BANNERS:
        path = ESubPaths.BANNERS;
        break;
      case EProjectTab.TEMPLATE:
        path = ESubPaths.TEMPLATE;
        break;
      default:
        path = '';
    }

    navigate(path, {
      relative: 'route',
      replace: false,
    });

    setBannerActivePage(key as EProjectTab);
  }, []);

  useLayoutEffect(() => {
    if (location.pathname.endsWith(ESubPaths.TEMPLATE)) {
      setBannerActivePage(EProjectTab.TEMPLATE);
    } else if (location.pathname.endsWith(ESubPaths.BANNERS)) {
      setBannerActivePage(EProjectTab.BANNERS);
    } else {
      setBannerActivePage(EProjectTab.PROJECT);
    }
  }, []);

  const { isLoading, data } = useGetByIdQuery(projectId as string, {
    skip: !projectId,
  });

  const afterDeleteCallback = useCallback(() => {
    navigate(EPaths.HOME);
  }, []);

  const handleDeleteProject = useDeleteProject(afterDeleteCallback);
  const handleStartEdit = () => {
    setCreateProjectModalOpen(true);
  };

  const pageBreadCrumbs: BreadcrumbProps['items'] = useMemo(
    () => [
      {
        title: (
          <NavLink to={EPaths.HOME}>
            <Space>
              <HomeOutlined />
              Проекты
            </Space>
          </NavLink>
        ),
      },
      {
        title: isLoading ? (
          <>
            <LoadingOutlined />
            Загрузка
          </>
        ) : (
          data?.name
        ),
      },
    ],
    [isLoading, data]
  );

  const pageExtraButtons: ReactNode = useMemo(
    () => (
      <>
        <Button icon={<EditOutlined />} onClick={handleStartEdit}>
          Редактировать проект
        </Button>
        <Popconfirm
          title="Вы уверены что хотите удалить проект?"
          okButtonProps={{
            danger: true,
          }}
          okText="Удалить"
          placement="bottomRight"
          onConfirm={() => {
            void handleDeleteProject(projectId);
          }}
        >
          <Button icon={<DeleteOutlined />} danger type="primary">
            Удалить проект
          </Button>
        </Popconfirm>
      </>
    ),
    [handleDeleteProject, projectId]
  );

  const handleStartGeneration = useCallback(async () => {
    if (projectId) {
      try {
        await startGeneration(projectId).unwrap();
        void messageApi.success('Генерация успешно запущена');
      } catch (e) {
        void messageApi.error('Ошибка при запуске генерации. Попробуйте позже');
      }
    } else {
      void messageApi.error(
        'Отсутствует идентификатор проекта, пожалуйста проверьте URL адрес страницы'
      );
    }
  }, [projectId]);

  const { data: bannersData } = useGetProjectBannersQuery(projectId as string, {
    skip: !projectId,
  });

  const handleDownloadBanners = useCallback(async () => {
    if (bannersData && bannersData.banners.length > 0) {
      setDownloadBannersPending(true);
      try {
        await downloadFiles(
          bannersData.banners.map((el) => el.imageURL)
        ).unwrap();
      } catch (e) {
        void messageApi.error('Произошла ошибка при скачивании');
      } finally {
        setDownloadBannersPending(false);
      }
    }
  }, [bannersData]);

  return (
    <AppSubjectPageLayout
      breadcrumbList={pageBreadCrumbs}
      extraButtons={pageExtraButtons}
    >
      <ProjectPageHeaderSection
        projectName={data?.name}
        projectTaskFullStatus={projectTaskFullStatus}
      />
      <Tabs
        activeKey={bannerActiveTab}
        onChange={onBannerTabKeyChange}
        items={projectTabs}
        tabBarExtraContent={
          <Space>
            {data && !!data.scheduledFeedURL && (
              <Link to={data.scheduledFeedURL} download target="_blank">
                <Button type="primary" ghost icon={<DownloadOutlined />}>
                  Скачать фид .xml
                </Button>
              </Link>
            )}
            {bannersData && bannersData.banners.length > 0 && (
              <Button
                type="primary"
                ghost
                loading={downloadBannersPending}
                icon={<FileZipOutlined />}
                onClick={handleDownloadBanners}
              >
                Скачать баннеры .zip
              </Button>
            )}
            <Button
              type="primary"
              loading={isStartGenerationLoading}
              icon={<PlayCircleOutlined />}
              onClick={handleStartGeneration}
            >
              Запустить генерацию
            </Button>
          </Space>
        }
      />
      <Routes>
        <Route
          index
          element={<ProjectMainInfoTab data={data} isLoading={isLoading} />}
        />
        <Route
          path={ESubPaths.BANNERS}
          element={<ProjectBannersTab projectId={projectId} />}
        />
        <Route
          path={ESubPaths.TEMPLATE}
          element={<ProjectTemplateTab projectId={projectId} />}
        />
      </Routes>
      <CreateProjectModal
        open={createProjectModalOpen}
        setOpen={setCreateProjectModalOpen}
        existProjectId={projectId}
      />
    </AppSubjectPageLayout>
  );
};

export default ProjectPage;
