import { FC, memo } from 'react';

import styled from '@emotion/styled';
import { Descriptions, Typography } from 'antd';

import { DescriptionsProps } from 'antd/es/descriptions';

type TProps = {
  items: DescriptionsProps['items'];
  title: string;
};

const Wrapper = styled.div`
  margin-top: 40px;
`;

export const OfferTextDescription = styled(Typography.Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const OfferDescription: FC<TProps> = memo(({ items, title }) => (
  <Wrapper>
    <Descriptions bordered title={title} items={items} />
  </Wrapper>
));

export default OfferDescription;
