import { FC } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Space } from 'antd';
import { FormInstance } from 'rc-field-form/es/interface';

import { TCategory } from '../../types/yandex-feed';
import { TYandexCurrencyOption } from '../../utils/yandex-feed';

import { FormListProps } from 'antd/es/form/FormList';

type TProps = {
  name: FormListProps['name'];
  setFieldValue: FormInstance['setFieldValue'];
  getFieldValue: FormInstance['getFieldValue'];
};

const CategoriesListForm: FC<TProps> = ({
  name: formListName,
  setFieldValue,
  getFieldValue,
}) => (
  <Form.List name={formListName}>
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => {
          const formDeps = [...fields.map((el) => [formListName, el.name])];
          // eslint-disable-next-line
          // @ts-ignore
          const fullDeps = formDeps.reduce(
            (acc, curr) => [...acc, [...curr, 'name'], [...curr, 'id']],
            []
          );

          const currentId = getFieldValue([formListName, name, 'id']);

          // При удалении элемента name меняется, необходимо обновлять значение id
          if (currentId !== name) {
            try {
              setTimeout(() => {
                setFieldValue([formListName, name, 'id'], name);
              }, 0);
            } catch (e) {
              /* empty */
            }
          }

          return (
            <Space
              key={key}
              style={{ display: 'flex', marginBottom: 8 }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'name']}
                label="Название"
                rules={[
                  {
                    required: true,
                    message: 'Поле обязательно для заполнения',
                  },
                ]}
              >
                <Input
                  style={{
                    width: 220,
                  }}
                  placeholder="Название"
                />
              </Form.Item>
              <Form.Item noStyle dependencies={[...fullDeps]}>
                {({ getFieldValue: getListFieldValue }) => {
                  const options = (
                    getListFieldValue(formListName) as TCategory[]
                  )
                    .filter((el) => !!el && el.id !== name)
                    .map((el) => ({
                      value: el.id,
                      label: el.name,
                    }));

                  return (
                    <Form.Item
                      {...restField}
                      label="Родительская категория"
                      name={[name, 'parentId']}
                    >
                      <Select<TYandexCurrencyOption>
                        placeholder="Родительская категория"
                        options={options}
                        showSearch
                        optionFilterProp="label"
                        notFoundContent="Не найдено"
                        popupMatchSelectWidth={false}
                        style={{
                          width: 220,
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                noStyle
                {...restField}
                name={[name, 'id']}
                hidden
                initialValue={name}
              >
                <Input aria-hidden value={name} />
              </Form.Item>
              {fields.length > 1 && (
                <MinusCircleOutlined
                  style={{
                    marginBottom: 32,
                  }}
                  onClick={() => remove(name)}
                />
              )}
            </Space>
          );
        })}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() =>
              add({
                id: fields.length,
              })
            }
            block
            icon={<PlusOutlined />}
          >
            Добавить категорию
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default CategoriesListForm;
