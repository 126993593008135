import { EYandexCurrencyCode } from '../types/yandex-feed';

export type TYandexCurrencyOption = {
  value: EYandexCurrencyCode;
  label: string;
};

export const YandexCurrencyOptions: TYandexCurrencyOption[] = [
  {
    value: EYandexCurrencyCode.RUR,
    label: 'Рубли - RUR',
  },
  {
    value: EYandexCurrencyCode.RUB,
    label: 'Рубли - RUB',
  },
  {
    value: EYandexCurrencyCode.UAH,
    label: 'Гривны - UAH',
  },
  {
    value: EYandexCurrencyCode.BYN,
    label: 'Белорусские рубли - BYN',
  },
  {
    value: EYandexCurrencyCode.KZT,
    label: 'Тенге - KZT',
  },
  {
    value: EYandexCurrencyCode.USD,
    label: 'Доллары - USD',
  },
  {
    value: EYandexCurrencyCode.EUR,
    label: 'Евро - EUR',
  },
];
