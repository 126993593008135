import { FC, PropsWithChildren, createContext, useContext } from 'react';

import { message } from 'antd';

type TContext = {
  messageApi: ReturnType<typeof message.useMessage>[0];
};

const context = createContext<TContext>({
  messageApi: message,
});

export const useAppNotificationApi = () => useContext(context);

const AppNotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [api, contextHolder] = message.useMessage();

  // Правило не актуально до тех пор пока не появятся зависимости
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: TContext = {
    messageApi: api,
  };

  return (
    <context.Provider value={contextValue}>
      {contextHolder}
      {children}
    </context.Provider>
  );
};
export default AppNotificationsProvider;
