import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { appThemeReducer } from './theme.slice';

const themeConfig = {
  key: 'app/theme',
  storage,
};

const appReducer = combineReducers({
  theme: persistReducer(themeConfig, appThemeReducer),
});

export default appReducer;
