import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Alert } from 'antd';

import { usePluginAuthMutation } from '../../api/auth';
import { ERouteParams } from '../../routes/route-params';
import { useAppSelector } from '../../state';
import { selectAuth } from '../../state/features/user/auth.slice';

const LoadIndicator = styled(LoadingOutlined)`
  width: 60px;
  height: 60px;
  color: ${({ theme }) => theme.MAIN.ACCENT.C100};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const PluginAuthPage: FC = () => {
  const [searchParams] = useSearchParams();
  const { authed } = useAppSelector(selectAuth);
  const [authPlugin, { isError, isUninitialized, isSuccess, isLoading, data }] =
    usePluginAuthMutation();

  const sessionId = useMemo(
    () => searchParams.get(ERouteParams.PLUGIN_SESSION_ID),
    [searchParams]
  );

  useEffect(() => {
    if (sessionId && isUninitialized && authed) {
      authPlugin({
        sessionId,
      });
    }
  }, [sessionId, isUninitialized, authed]);

  useEffect(() => {
    if (data && data.redirect) {
      window.open(data.redirect);
    }
  }, [data]);

  return (
    <Wrapper>
      {isLoading && <LoadIndicator />}
      {isError && (
        <Alert
          message="Произошла ошибка"
          description="При авторизации произошла ошибка, попробуйте позже"
          type="error"
        />
      )}
      {isSuccess && <Alert message="Вход успешно выполнен" type="success" />}
      {!sessionId && (
        <Alert
          message="Отсутствует идентификатор"
          description="В URL адресе не обнаружен параметр идентификации, перейдите по ссылке плагина повторно"
          type="warning"
        />
      )}
    </Wrapper>
  );
};
