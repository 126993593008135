import { NavLink } from 'react-router-dom';

import { Checkbox } from 'antd';
import dayjs from 'dayjs';

import TableActionComponent from '../components/ui/table/table-action-component';
import { TTableActions } from '../types/ui';

import { ColumnsType } from 'antd/lib/table';

export type TProjectColumnsData = {
  key: string; // ID
  name: string;
  feedUrl: string;
  scheduleEnabled: boolean;
  createAt: string;
  updateAt: string;
};

export const getProjectListTableColumns: (
  actions?: TTableActions[]
) => ColumnsType<TProjectColumnsData> = (actions) =>
  [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Название',
      render: (name: string, data: TProjectColumnsData) => (
        <NavLink to={data.key}>{name}</NavLink>
      ),
    },
    {
      key: 'scheduleEnabled',
      dataIndex: 'scheduleEnabled',
      title: 'Расписание',
      render: (value: boolean) => <Checkbox checked={value} />,
    },
    {
      key: 'feedUrl',
      dataIndex: 'feedUrl',
      title: 'Файл конфигурации',
      render: (value: string) => (
        <a href={value} download target="_blank" rel="noreferrer">
          Скачать файл
        </a>
      ),
    },
    {
      key: 'createAt',
      dataIndex: 'createAt',
      title: 'Дата создания',
      render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
    },
    {
      key: 'updateAt',
      dataIndex: 'updateAt',
      title: 'Дата обновления',
      render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
    },
    !!actions && {
      key: 'actions',
      dataIndex: 'key',
      render: (id: string) => (
        <TableActionComponent
          items={actions.map((el) => ({
            key: el.key,
            label: el.content,
            icon: el.icon,
            onClick: () => el.action(id),
          }))}
        />
      ),
    },
  ].filter(Boolean) as ColumnsType<TProjectColumnsData>;
