import { FC, useCallback, useMemo } from 'react';

import { ButtonProps, Checkbox, Form, Modal } from 'antd';

import BaseYandexFeedForm from '../../components/forms/base-yandex-feed-form';
import CategoriesListForm from '../../components/forms/categories-list-form';
import CurrencyListForm from '../../components/forms/currency-list-form';
import DeliverAndPickupForm from '../../components/forms/deliver-and-pickup-form';
import { FormTitle } from '../../components/forms/form-components';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { TBaseModalProps } from '../../types/ui';
import { TYandexFeed } from '../../types/yandex-feed';

type TProps = TBaseModalProps & {
  handleCreateFeed: (data: TYandexFeed<number>) => Promise<string>;
  isLoading: boolean;
};

export const CreateYandexFeedModal: FC<TProps> = ({
  open,
  setOpen,
  handleCreateFeed,
  isLoading,
}) => {
  const [form] = Form.useForm<TYandexFeed>();

  const { messageApi } = useAppNotificationApi();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFormFinish = useCallback(async (values: TYandexFeed) => {
    try {
      const dataToLoad: TYandexFeed<number> = {
        ...values,
        'delivery-options': values['delivery-options']?.map((el) => ({
          ...el,
          'order-before': el['order-before']?.get('hours'),
        })),
        'pickup-options': values['pickup-options']?.map((el) => ({
          ...el,
          'order-before': el['order-before']?.get('hours'),
        })),
      };

      const response = await handleCreateFeed(dataToLoad);

      messageApi.success('Фид успешно создан');

      if (response) {
        try {
          await navigator.clipboard.writeText(response);
          messageApi.success('URL файла скопирован в буффер обмена');
        } catch (e) {
          messageApi.error(
            'Не удалось скопировать ссылку на созданный фид, скопируйте вручную из списка фидов'
          );
        }
      }

      setOpen(false);
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error on feed creation', e);
      messageApi.error('Произошла ошибка при создании фида');
    }
  }, []);

  const okButtonProps: ButtonProps = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  );

  return (
    <Modal
      title="Создать фид"
      open={open}
      onCancel={handleCancel}
      onOk={form.submit}
      maskClosable={false}
      okButtonProps={okButtonProps}
      okText="Сохранить"
    >
      <Form<TYandexFeed>
        form={form}
        layout="vertical"
        onFinish={handleFormFinish}
        initialValues={{
          currencies: [
            {
              rate: 1,
            },
          ],
          categories: [{}],
        }}
      >
        <FormTitle>Основная информация</FormTitle>
        <BaseYandexFeedForm />
        <FormTitle>Валюты</FormTitle>
        <CurrencyListForm name="currencies" />
        <FormTitle>Категории</FormTitle>
        <Form.Item noStyle shouldUpdate>
          {({ setFieldValue, getFieldValue }) => (
            <CategoriesListForm
              name="categories"
              setFieldValue={setFieldValue}
              getFieldValue={getFieldValue}
            />
          )}
        </Form.Item>
        <FormTitle>Доставка и получение</FormTitle>
        <DeliverAndPickupForm />
        <FormTitle>Прочее</FormTitle>
        <Form.Item
          label="Скидки"
          tooltip="Автоматический расчет и показ скидок для всего прайс-листа."
          name="enable_auto_discounts"
          valuePropName="checked"
        >
          <Checkbox>Автоматический расчет и показ скидок</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
