import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
`;

const Inner = styled.div`
  min-width: 320px;
`;

const AppFormLayout: FC = () => (
  <Wrapper>
    <Inner>
      <Outlet />
    </Inner>
  </Wrapper>
);

export default AppFormLayout;
