import { FC, memo } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Select, Space } from 'antd';

import {
  TYandexCurrencyOption,
  YandexCurrencyOptions,
} from '../../utils/yandex-feed';

import { FormListProps } from 'antd/es/form/FormList';

type TProps = {
  name: FormListProps['name'];
};

const CurrencyListForm: FC<TProps> = ({ name: formListName }) => (
  <Form.List name={formListName}>
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => (
          <Space
            key={key}
            style={{ display: 'flex', marginBottom: 8 }}
            align="baseline"
          >
            <Form.Item
              {...restField}
              name={[name, 'id']}
              label="Валюта"
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <Select<TYandexCurrencyOption>
                placeholder="Валюта"
                options={YandexCurrencyOptions}
                showSearch
                optionFilterProp="label"
                notFoundContent="Не найдено"
                popupMatchSelectWidth={false}
                style={{
                  width: 220,
                }}
              />
            </Form.Item>
            <Form.Item
              {...restField}
              name={[name, 'rate']}
              label="Ставка"
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <InputNumber
                style={{
                  width: 220,
                }}
                placeholder="Ставка"
                min={0}
                step={0.1}
              />
            </Form.Item>
            {fields.length > 1 && (
              <MinusCircleOutlined
                style={{
                  marginBottom: 32,
                }}
                onClick={() => remove(name)}
              />
            )}
          </Space>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() =>
              add({
                rate: 1,
              })
            }
            block
            icon={<PlusOutlined />}
          >
            Добавить валюту
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default memo(CurrencyListForm);
