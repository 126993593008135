import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { APP_ROOT_ID } from './constants';
import { AppProvider } from './context/providers/app-provider';
import { RootRoutes } from './routes/root-routes';

const container = document.getElementById(APP_ROOT_ID);

function App() {
  return (
    <StrictMode>
      <AppProvider>
        <RootRoutes />
      </AppProvider>
    </StrictMode>
  );
}

if (container) {
  const root = createRoot(container);

  root.render(<App />);
} else {
  throw new Error("Корневой элемент c id='root' не найден");
}
