import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { selectAuth } from '../state/features/user/auth.slice';

const BASE_URL = process.env.API_HOST;

export const FILE_UPLOAD_API = `${BASE_URL}api/files/upload`;

export const baseEmptyApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = selectAuth(getState() as never);
      if (token?.accessToken) {
        headers.set('Authorization', `Bearer ${token.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
