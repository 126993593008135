export enum EPaths {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  PLUGIN_AUTH = '/plugin-auth',
  PROFILE = '/profile',
  FEEDS = '/feeds',
}

export enum EPathKeyParams {
  PROJECT_ID = 'projectId',
}

export enum ESubPaths {
  BANNERS = 'banners',
  TEMPLATE = 'template',
}

export const UNSECURE_PATHS: EPaths[] = [EPaths.LOGIN, EPaths.REGISTER];
