import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth.slice';

const authConfig = {
  key: 'auth',
  storage,
};

export const userReducer = combineReducers({
  auth: persistReducer(authConfig, authReducer),
});
