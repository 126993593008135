export interface LoginRequest {
  publicKey: string;
  privateKey: string;
}

export interface RegistrationRequest {
  publicKey: string;
  privateKey: string;
  rePrivateKey: string;
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
}

export enum EProfileType {
  INDIVIDUAL = 'INDIVIDUAL',
  LEGAL = 'BUSINESS',
}

type TUserProfileIndividual = {
  firstName: string;
  lastName: string;
  middleName?: string;
  phoneNumber?: string;
};

type TUserProfileLegal = {
  companyPhoneNumber?: string;
  companyName: string;
  inn: string;
  ogrn?: string;
  legalAddress?: string;
  physicalAddress?: string;
} & TUserProfileIndividual;

type TUserProfileType =
  | {
      profileType: EProfileType.INDIVIDUAL;
      profile: null | TUserProfileIndividual;
    }
  | {
      profileType: EProfileType.LEGAL;
      profile: null | TUserProfileLegal;
    };

export type TProfileForm =
  | {
      profileType: EProfileType.LEGAL;
      firstName: string;
      lastName: string;
      middleName?: string;
      phoneNumber?: string;
      companyPhoneNumber?: string;
      companyName: string;
      inn: string;
      ogrn?: string;
      legalAddress?: string;
      physicalAddress?: string;
    }
  | {
      profileType: EProfileType.INDIVIDUAL;
      firstName: string;
      lastName: string;
      middleName?: string;
      phoneNumber?: string;
    };

export type UserResponse = {
  id: string;
  name: null | string;
  login: null | string;
  email: string;
  role: 'user';
  createAt: string;
  updateAt: string;
} & TUserProfileType;

export type UserPatchPayload = {
  data: Partial<
    Omit<UserResponse, 'id' | 'email' | 'createAt' | 'updateAt' | 'role'>
  >;
  id: string;
};

export interface PluginAuthRequest {
  sessionId: string;
}

export interface PluginAuthResponse {
  redirect: string | null;
}
