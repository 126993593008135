import { FC } from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Space, TimePicker } from 'antd';

import { FormListProps } from 'antd/es/form/FormList';

type TProps = {
  name: FormListProps['name'];
};

const DeliveryOptionsListForm: FC<TProps> = ({ name: formListName }) => (
  <Form.List name={formListName}>
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key, name, ...restField }) => (
          <Space
            key={key}
            style={{ display: 'flex', marginBottom: 8 }}
            align="end"
          >
            <Form.Item
              {...restField}
              label="Стоимость"
              name={[name, 'cost']}
              required
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <InputNumber
                style={{
                  width: 140,
                }}
                placeholder="Стоимость"
                min={0}
                step={100}
              />
            </Form.Item>
            <Form.Item
              {...restField}
              label="Срок"
              name={[name, 'days']}
              required
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <InputNumber
                style={{
                  width: 140,
                }}
                placeholder="Срок"
                min={0}
                addonAfter="дней"
              />
            </Form.Item>
            <Form.Item
              {...restField}
              label="Крайнее время"
              tooltip="Время, до которого нужно сделать заказ, чтобы получить его в этот срок"
              name={[name, 'order-before']}
              valuePropName="pickerValue"
            >
              <TimePicker
                style={{
                  width: 140,
                }}
                placeholder="Время"
                showNow={false}
                showMinute={false}
                showSecond={false}
              />
            </Form.Item>
            <MinusCircleOutlined
              style={{
                marginBottom: 32,
              }}
              onClick={() => remove(name)}
            />
          </Space>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() => add()}
            block
            icon={<PlusOutlined />}
          >
            Добавить вариант
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default DeliveryOptionsListForm;
