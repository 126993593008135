export type TAppTheme = {
  WHITE: {
    C100: string;
    C200: string;
    C300: string;
    C400: string;
    C500: string;
    C900: string;
    C100_TRANSPARENT: string;
  };
  TEXT: {
    C100: string;
    C200: string;
    C300: string;
  };
  TEXT_WHITE: {
    C100: string;
    C200: string;
    C300: string;
  };
  BLACK: {
    C100: string;
    C200: string;
    C300: string;
    C100_TRANSPARENT: string;
  };
  ACCENT: {
    C100: string;
    C200: string;
    C300: string;
  };
  DANGER: {
    C100: string;
    C200: string;
  };
  SUCCESS: {
    C100: string;
    C200: string;
  };
  WARNING: {
    C100: string;
    C200: string;
  };
};

export const THEME_LIGHT: TAppTheme = {
  WHITE: {
    C100: '#FFFFFF',
    C200: '#F1F3F6',
    C300: '#EAEAF2',
    C400: '#e0e0e5',
    C500: '#c5c5c9',
    C900: '#e0e0e5',
    C100_TRANSPARENT: 'rgba(255,255,255,0.7)',
  },
  TEXT: {
    C100: '#242831',
    C200: '#4A5262',
    C300: '#717171',
  },
  TEXT_WHITE: {
    C100: '#FFFFFF',
    C200: '#B0B0B0',
    C300: '#696969',
  },
  BLACK: {
    C100: '#242831',
    C200: '#4A5262',
    C300: '#717171',
    C100_TRANSPARENT: 'rgba(36,40,49,0.3)',
  },
  ACCENT: {
    C100: '#135eec',
    C200: '#2068ef',
    C300: '#5086ec',
  },
  DANGER: {
    C100: '#EC4E4E',
    C200: '#D44747',
  },
  SUCCESS: {
    C100: '#40D2AD',
    C200: '#42BB9C',
  },
  WARNING: {
    C100: '#EBB834',
    C200: '#D2A83B',
  },
};
