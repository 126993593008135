import { configureStore } from '@reduxjs/toolkit/react';
import { useDispatch, useSelector } from 'react-redux';

import { persistStore } from 'redux-persist';

import { baseEmptyApi } from '../api';
import appReducer from './features/app';
import { userReducer } from './features/user';
import authMiddleware from './middleware/auth.middleware';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    [baseEmptyApi.reducerPath]: baseEmptyApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([authMiddleware, baseEmptyApi.middleware]),
});

export const persistedStore = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
