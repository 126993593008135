import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { CONTEXT_ROOT } from '../../constants';
import { persistedStore, store } from '../../state';
import AppNotificationsProvider from './app-notifications-provider';
import AuthProvider from './auth-provider';
import ThemeProvider from './theme-provider';

dayjs.locale('ru');

export const AppProvider: FC<PropsWithChildren> = ({ children }) => (
  <Provider store={store}>
    <PersistGate persistor={persistedStore} loading={null}>
      <BrowserRouter basename={CONTEXT_ROOT}>
        <ThemeProvider>
          <AppNotificationsProvider>
            <AuthProvider>{children}</AuthProvider>
          </AppNotificationsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
