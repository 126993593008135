import { FC, useCallback, useMemo, useState } from 'react';

import { Button, Space, Table } from 'antd';

import {
  useCreateYandexFeedMutation,
  useGetFeedsListQuery,
} from '../../api/feeds';
import {
  TYandexFeedsColumnsData,
  getYandexFeedsTableColumns,
} from '../../tables/yandex-feeds';
import { TYandexFeed } from '../../types/yandex-feed';
import { CreateYandexFeedModal } from '../modals/create-yandex-feed-modal';

import { ColumnsType } from 'antd/lib/table';

export const FeedsPage: FC = () => {
  const { data, isLoading, refetch } = useGetFeedsListQuery();
  const [createFeedModalOpen, setCreateFeedModalOpen] = useState(false);

  const [createYandexFeed, { isLoading: isFeedCreationLoading }] =
    useCreateYandexFeedMutation();

  const handleOpenFeedModal = () => {
    setCreateFeedModalOpen(true);
  };

  const columnData: TYandexFeedsColumnsData[] = useMemo(
    () =>
      data
        ? data.map((el) => ({
            key: el.id,
            originalName: el.originalName,
            location: el.location,
            size: el.size,
            updateAt: el.updateAt,
            createAt: el.createAt,
          }))
        : [],
    [data]
  );

  const columns: ColumnsType<TYandexFeedsColumnsData> = useMemo(
    () => getYandexFeedsTableColumns(),
    []
  );

  const handleCreateFeed: (feedData: TYandexFeed<number>) => Promise<string> =
    useCallback(async (feedData: TYandexFeed<number>) => {
      const response = await createYandexFeed(feedData).unwrap();
      refetch();

      return response;
    }, []);

  return (
    <div>
      <Space>
        <Button
          type="primary"
          onClick={handleOpenFeedModal}
          style={{
            marginBottom: 20,
          }}
        >
          Создать фид
        </Button>
      </Space>
      <Table
        dataSource={columnData}
        columns={columns}
        loading={isLoading}
        pagination={false}
      />
      <CreateYandexFeedModal
        open={createFeedModalOpen}
        setOpen={setCreateFeedModalOpen}
        isLoading={isFeedCreationLoading}
        handleCreateFeed={handleCreateFeed}
      />
    </div>
  );
};
