import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

import { ECSSVars } from '../../../styles/global-styles';
import { AppBaseMenu } from './app-base-menu';
import { TMenuItem } from './base-menu-item';

const Wrapper = styled.main`
  height: 100vh;
  overflow-y: auto;
  position: relative;
  padding-left: var(${ECSSVars.APP_MENU_WIDTH});

  &::-webkit-scrollbar {
    background: transparent;
    width: 10px;
    max-width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.MAIN.BLACK.C300};
    border-radius: 5px;
    width: 10px;
    height: 10px;
  }
`;

const Inner = styled.div`
  padding: 20px;
  min-height: 100vh;
`;

const AppBaseLayout: FC = () => (
  <Wrapper>
    <AppBaseMenu />
    <Inner>
      <Outlet />
    </Inner>
  </Wrapper>
);

export { TMenuItem };
export default AppBaseLayout;
