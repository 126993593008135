import styled from '@emotion/styled';

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.MAIN.TEXT.C200};
`;

export const FormSubTitle = styled.h4`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.MAIN.TEXT.C300};
`;
