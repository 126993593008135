import { FC, useCallback, useEffect } from 'react';

import styled from '@emotion/styled';
import { Button, Form, Input, Radio } from 'antd';

import { usePatchUserMutation } from '../../api/auth';
import { EProfileType, TProfileForm } from '../../api/auth/types';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { useAppDispatch, useAppSelector } from '../../state';
import { logout, selectAuth } from '../../state/features/user/auth.slice';

const FormWrapper = styled.div`
  margin: 0 auto;
  max-width: 400px;
`;

const FormButton = styled(Button)`
  width: 100%;
`;

export const ProfilePage: FC = () => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector(selectAuth);
  const [form] = Form.useForm<TProfileForm>();

  const { messageApi } = useAppNotificationApi();

  const [patchUser, { isLoading }] = usePatchUserMutation();

  useEffect(() => {
    if (userData) {
      if (userData.profile) {
        form.setFieldsValue({
          ...userData.profile,
        });
      }

      form.setFieldsValue({
        profileType: userData.profileType,
      });
    }
  }, [userData]);

  const onFormFinish = useCallback(async (values: TProfileForm) => {
    if (userData) {
      try {
        const { profileType, ...profileValues } = values;

        await patchUser({
          data: {
            profileType,
            profile: profileValues,
          },
          id: userData.id,
        }).unwrap();
        messageApi.success('Данные успешно изменены');
      } catch (e) {
        messageApi.error(
          'Произошла ошибка при редактировании профиля, попробуйте позже'
        );
      }
    }
  }, []);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      <FormWrapper>
        <h1>Данные профиля</h1>
        <Form<TProfileForm>
          form={form}
          layout="vertical"
          onFinish={onFormFinish}
        >
          <Form.Item label="Тип профиля" name="profileType">
            <Radio.Group>
              <Radio value={EProfileType.INDIVIDUAL}>Физ. лицо</Radio>
              <Radio value={EProfileType.LEGAL}>Юр. лицо</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle dependencies={['profileType']}>
            {({ getFieldValue }) => {
              const isLegal =
                getFieldValue('profileType') === EProfileType.LEGAL;

              return (
                <>
                  <Form.Item
                    label={isLegal ? 'Имя ответственного лица' : 'Имя'}
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={isLegal ? 'Фамилия ответственного лица' : 'Фамилия'}
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Поле обязательно для заполнения',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      isLegal ? 'Отчество ответственного лица' : 'Отчество'
                    }
                    name="middleName"
                    tooltip="Введите при наличии"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      isLegal
                        ? 'Номер телефона ответственного лица'
                        : 'Номер телефона'
                    }
                    name="phoneNumber"
                  >
                    <Input placeholder="+71234567890" />
                  </Form.Item>
                  {isLegal && (
                    <>
                      <Form.Item
                        label="Номер телефона компании"
                        name="companyPhoneNumber"
                      >
                        <Input placeholder="+71234567890" />
                      </Form.Item>
                      <Form.Item
                        label="Название организации"
                        name="companyName"
                        rules={[
                          {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="ИНН организации"
                        name="inn"
                        rules={[
                          {
                            required: true,
                            message: 'Поле обязательно для заполнения',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="ОГРН организации" name="ogrn">
                        <Input />
                      </Form.Item>
                      <Form.Item label="Юридический адрес" name="legalAddress">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Физический адрес"
                        name="physicalAddress"
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}
                </>
              );
            }}
          </Form.Item>
          <Form.Item>
            <FormButton
              type="primary"
              onClick={form.submit}
              loading={isLoading}
            >
              Сохранить
            </FormButton>
          </Form.Item>
          <Form.Item>
            <FormButton onClick={handleLogout}>Выйти из профиля</FormButton>
          </Form.Item>
        </Form>
      </FormWrapper>
    </div>
  );
};
