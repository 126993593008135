import { CopyOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import dayjs from 'dayjs';

import { ColumnsType } from 'antd/lib/table';

export type TYandexFeedsColumnsData = {
  key: string; // ID
  originalName: string;
  location: string;
  size: number;
  updateAt: string;
  createAt: string;
};

// Реализовано через функцию для передачи параметров в будущем
export const getYandexFeedsTableColumns: () => ColumnsType<TYandexFeedsColumnsData> =
  () => [
    {
      key: 'name',
      dataIndex: 'originalName',
      title: 'Название файла',
    },
    {
      key: 'size',
      dataIndex: 'size',
      title: 'Размер файла',
      render: (size: number) =>
        size < 1024 ? `${size} б.` : `${(size / (1024 * 1024)).toFixed(2)} мб.`,
    },
    {
      key: 'location',
      dataIndex: 'location',
      title: 'Ссылка',
      render: (location: string) => (
        <Space align="baseline">
          <Typography.Text>{location}</Typography.Text>
          <Button
            shape="circle"
            icon={<CopyOutlined />}
            onClick={() => {
              void navigator.clipboard.writeText(location);
            }}
          />
        </Space>
      ),
    },
    {
      key: 'createAt',
      dataIndex: 'createAt',
      title: 'Дата создания',
      render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
    },
    {
      key: 'updateAt',
      dataIndex: 'updateAt',
      title: 'Дата обновления',
      render: (value: string) => dayjs(value).format('DD.MM.YYYY HH:mm'),
    },
  ];
