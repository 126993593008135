import { TYandexFeed } from '../../types/yandex-feed';
import { baseEmptyApi } from '../api';
import { TFeedListResponse } from './types';

export const feedsApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getFeedsList: builder.query<TFeedListResponse, void>({
      query: () => ({
        url: 'api/files/',
        method: 'GET',
        params: {
          mimetype: 'application/xml',
        },
      }),
    }),
    createYandexFeed: builder.mutation<string, TYandexFeed<number>>({
      query: (data) => ({
        url: 'api/feeds/yandex',
        method: 'POST',
        body: {
          ...data,
          offers: [],
        },
        responseHandler: 'text',
      }),
    }),
  }),
});

export const { useGetFeedsListQuery, useCreateYandexFeedMutation } = feedsApi;
