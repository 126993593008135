import { Image } from 'antd';

import { EFeedDescriptionType, TFullProject } from '../api/projects/types';
import { OfferTextDescription } from '../components/ui/offer-description';

import { DescriptionsProps } from 'antd/es/descriptions';

function getOfferChildrenByType(
  type: EFeedDescriptionType,
  description: string
) {
  switch (type) {
    case EFeedDescriptionType.IMAGE:
      return (
        <Image
          src={description}
          style={{
            maxWidth: 320,
            maxHeight: 320,
            width: '100%',
            height: '100%',
          }}
        />
      );
    case EFeedDescriptionType.TEXT:
      return <OfferTextDescription>{description}</OfferTextDescription>;
    default:
      return <OfferTextDescription>{description}</OfferTextDescription>;
  }
}

export function mapFeedOfferDescriptionToItemsProps(
  data: TFullProject['feedOfferDescriptions']
): DescriptionsProps['items'] {
  return data.map((el) => ({
    key: el.name,
    label: el.name,
    children: getOfferChildrenByType(el.type, el.description),
    span: 3,
  }));
}
