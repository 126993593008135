import { baseEmptyApi } from '../api';
import {
  AuthResponse,
  LoginRequest,
  PluginAuthRequest,
  PluginAuthResponse,
  RegistrationRequest,
  UserPatchPayload,
  UserResponse,
} from './types';

export const authApi = baseEmptyApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginRequest>({
      query: (body) => ({
        url: '/api/auth/login',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<AuthResponse, RegistrationRequest>({
      query: (body) => ({
        url: '/api/auth/register',
        method: 'POST',
        body,
      }),
    }),
    pluginAuth: builder.mutation<PluginAuthResponse, PluginAuthRequest>({
      query: (body) => ({
        url: '/api/auth/plugin',
        method: 'POST',
        body,
      }),
    }),
    refreshToken: builder.query<AuthResponse, void>({
      query: () => ({
        url: '/api/auth/refresh-token',
        method: 'GET',
      }),
    }),
    userInfo: builder.query<UserResponse, void>({
      query: () => ({
        url: '/api/auth/me',
        method: 'GET',
      }),
    }),
    checkToken: builder.query<boolean, void>({
      query: () => ({
        url: '/api/auth/check-token',
        method: 'GET',
      }),
    }),
    patchUser: builder.mutation<UserResponse, UserPatchPayload>({
      query: (payload) => ({
        url: `/api/users/${payload.id}`,
        method: 'PATCH',
        body: payload.data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            authApi.endpoints.userInfo.initiate(undefined, {
              forceRefetch: true,
            })
          );
        } catch (e) {
          // some error
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useUserInfoQuery,
  usePluginAuthMutation,
  useCheckTokenQuery,
  usePatchUserMutation,
} = authApi;
